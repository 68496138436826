<template>
  <b-sidebar
    id="add-new-visit-sidebar"
    :visible="isAddNewVisitSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation(resetblankvisit).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ $t("newVisit") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankvisit
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('visitDate')" label-for="visitDate">
              <b-form-datepicker
                id="visitDate"
                v-model="blankVisitData.visitdate"
                :locale="$i18n.locale"
                autofocus
                :state="
                  formValidation(resetblankvisit).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- numberofdairy -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group
              :label="$t('dairyCowCount')"
              label-for="numberofdairy"
            >
              <b-form-input
                id="numberofdairy"
                v-model="blankVisitData.numberofdairy"
                autofocus
                type="number"
                :state="
                  formValidation(resetblankvisit).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- numberofbeef -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('beefCowCount')" label-for="numberofbeef">
              <b-form-input
                id="numberofbeef"
                v-model="blankVisitData.numberofbeef"
                type="number"
                autofocus
                :state="
                  formValidation(resetblankvisit).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- milkamount -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('milkAmount')" label-for="milkamount">
              <b-form-input
                id="milkamount"
                v-model="blankVisitData.milkamount"
                type="number"
                autofocus
                :state="
                  formValidation(resetblankvisit).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- milkavarage -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('milkAvarage')" label-for="milkavarage">
              <b-form-input
                id="milkavarage"
                v-model="blankVisitData.milkavarage"
                autofocus
                type="number"
                :state="
                  formValidation(resetblankvisit).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- feedused -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('feedUsed')" label-for="feedused">
              <b-form-input
                id="feedused"
                v-model="blankVisitData.feedused"
                autofocus
                :state="
                  formValidation(resetblankvisit).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- report -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('report')" label-for="report">
              <b-form-textarea
                id="report"
                v-model="blankVisitData.report"
                autofocus
                :state="
                  formValidation(resetblankvisit).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import dayjs from "dayjs";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewVisitSidebarActive",
    event: "update:is-add-new-visit-sidebar-active",
  },
  props: {
    isAddNewVisitSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankVisitData: {
        id: 0,
        visitdate: dayjs().format(),
        numberofdairy: "",
        numberofbeef: "",
        milkamount: "",
        milkavarage: "",
        feedused: "",
        report: "",
        plantid: this.$store.state.app.selectedPlantId,
      },

      formValidation: formValidation,
    };
  },

  methods: {
    async changed(val) {
      this.$emit("update:is-add-new-visit-sidebar-active", val);
      this.resetblankvisit();
    },
    async onSubmit() {
      var response = await this.$http.post(
        "/PlantVisits/AddPlantVisit/",
        this.blankVisitData
      );
      this.$emit("fetchvisit");
      if (response.data.status == "OK")
        this.$emit("update:is-add-new-visit-sidebar-active", false);
    },
    async resetblankvisit() {
      this.blankVisitData = {
        visitdate: dayjs().format(),
        numberofdairy: 0,
        numberofbeef: 0,
        milkamount: 0,
        milkavarage: 0,
        feedused: "",
        report: "",
        plantid: this.$store.state.app.selectedPlantId,
        id: 0,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-visit-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
